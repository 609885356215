<template>
    <div class="search">
        <div class="search__content">
            <div class="search__content__texts">
                <h1>Szukaj produktów</h1>
                <h3>Znajdź najtańsze laptopy, komputery poleasingowe, monitory oraz akcesoria.</h3>
            </div>
            <div class="search__content__input">
                <div class="search__content__input__getQuery">
                    <i class="fi fi-rr-search search__content__input__getQuery__icon"></i>
                    <input type="text" placeholder="Szukaj przedmiotów.." v-model="query" v-on:keyup.enter="searchAlgolia()">
                    <button @click="searchAlgolia()">Wyszukaj</button>
                </div>
            </div>
                <transition name="fade">
                <div class="modal__filters__category" v-if="products.length>0">
                    <div class="modal__filters__category__name">
                        <h4>Sortowanie</h4>
                    </div>
                    <div class="modal__filters__category__select">
                        <select v-model="chosenValues.sort" @change="changeValues()">
                            <option value="acc">Trafność</option>
                            <option value="sort">Priorytet</option>
                            <option value="asc">Cena rosnąco</option>
                            <option value="dsc">Cena malejąco</option>
                            <option value="alphabet">Alfabetycznie</option>
                        </select>
                    </div>
                </div>
                </transition>

            <transition name="fade">
                <div class="search__content__results" v-if="products.length>0">
                    <div class="search__content__results__text" v-if="lastQuery.length>0">
                        Znalezione produkty dla frazy: "{{lastQuery}}"
                    </div>
                    <div class="search__content__results__row" v-for="i in Math.ceil(products.length / 4)" :key="i">
                        <productTile :product="product" v-for="product in products.slice((i - 1) * 4, i * 4)" :key="product.uniqueID"></productTile>
                    </div>
                </div>
            </transition>
            
        </div>
        <transition name="fade">
            <beforeSearch v-if="notSearchYet"></beforeSearch>
        </transition>
        <transition name="fade">
            <div class="noDataSearch" v-if="noDataFound">
                <span class="noDataSearch__head">Brak wyników dla tego wyszukiwania.</span>
                <span>Spróbuj ponownie używajac innej frazy.</span>
            </div>
        </transition>
        <navbar/>
        <trust/>
        <ftr/>
    </div>
</template>
<script>
    // Imports
    import algoliasearch from 'algoliasearch/lite';
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";
    import beforeSearch from "../components/emptyStateSearchBefore.vue";
    import productTile from "../components/productTile.vue";

    export default {
        data() {
            return {
                query: "",
                notSearchYet: true,
                noDataFound: false,
                products: [],
                lastQuery: "",
                chosenValues: {
                    sort: "acc",
                },
                accurateValue: [],
            }
        },
        methods:
        {
            changeValues() {
                if(this.chosenValues.sort === 'acc') {
                    let productsToSort = this.products
                    for(let i=0; i<productsToSort.length; i++) {
                        let exactProduct = productsToSort[i]
                        if(exactProduct.model.toLowerCase().includes(this.query.toLowerCase()) === true) {
                            exactProduct.exact = 1
                        } else {
                            exactProduct.exact = 2
                        }
                       productsToSort.sort((a, b) => parseFloat(a.exact) - parseFloat(b.exact));
                    }
                }
                if(this.chosenValues.sort === 'sort') {
                    let productsToSort = this.products
                    for(let i=0; i<productsToSort.length;i++){
                        let currentProduct = productsToSort[i]
                        if(currentProduct.category === "Laptopy"){
                            currentProduct.order = 1
                        }
                        if(currentProduct.category === "Tablety"){
                            currentProduct.order = 2
                        }
                        if(currentProduct.category === "Komputery"){
                            currentProduct.order = 3
                        }
                        if(currentProduct.category === "Monitory"){
                            currentProduct.order = 4
                        }
                        if(currentProduct.category === "Telefony"){
                            currentProduct.order = 5
                        }
                        if(currentProduct.category === "Części laptopowe"){
                            currentProduct.order = 6
                        }
                        if(currentProduct.category === "Części komputerowe"){
                            currentProduct.order = 7
                        }
                        if(currentProduct.category === "Akcesoria"){
                            currentProduct.order = 8
                        }
                        if(currentProduct.category === "Usługi"){
                            currentProduct.order = 9
                        }
                        if(currentProduct.category === "Oprogramowanie"){
                            currentProduct.order = 10
                        }
                        if(currentProduct.category !== "Komputery" && currentProduct.category !== "Laptopy" && currentProduct.category !== "Monitory" && currentProduct.category !== "Części laptopowe" && currentProduct.category !== "Części komputerowe" && currentProduct.category !== "Akcesoria" && currentProduct.category !== "Tablety" && currentProduct.category !== "Telefony" && currentProduct.category !== "Oprogramowanie" && currentProduct.category !== "Usługi"){
                            currentProduct.order = 11
                        }
                    productsToSort.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
                    }
                }
                if(this.chosenValues.sort === 'asc') {
                    this.products.sort(function compareNumbers(a, b) {
                        return a.basePrice - b.basePrice
                    });
                }
                if(this.chosenValues.sort === 'dsc') {
                    this.products.reverse();
                };
                if(this.chosenValues.sort === 'alphabet') {
                    this.products.sort(function(a , b) {
                        if(a.name < b.name) {
                            return -1;
                        }
                        if(a.name > b.name) {
                            return 1;
                        }
                        return 0
                })
                }
            },
            test(){
                this.$store.commit('reset');
            },
            searchAlgolia()
            {
                if(this.query !== this.$route.query.search){
                this.$router.replace({query: { search: `${this.query}` }})
                }
                this.products=[];
                if(this.query.length>0)
                {
                    console.log(this.query);
                    const client = algoliasearch('IWMHPEMO0O', '7057f6d49026b8179b6746bc4fce229f');
                    const index = client.initIndex('Product');
                    index.search(this.query,{filters: "active:true", hitsPerPage: 300}).then(({ hits }) => {
                        console.log(hits);
                    if(hits.length>0)
                    {
                        this.lastQuery = this.query;
                        this.notSearchYet = false;
                        this.noDataFound = false;
                        let productsToSort = hits

                        this.products = productsToSort;
                        this.changeValues()
                    }
                    else
                    {
                        this.notSearchYet = false;
                        setTimeout(()=>
                        {
                            this.noDataFound = true;
                        },700)
                    }
                    });
                }
                else
                {
                    this.notSearchYet = false;
                    setTimeout(()=>
                    {
                        this.noDataFound = true;
                    },700)
                    return 0;
                }
            }
        },
        mounted()
        {
            window.scrollTo(0,0);
            if(this.$route.query.search !== undefined){
                this.query = this.$route.query.search;
                this.searchAlgolia();
            }
        },
        components: {navbar,trust,ftr,beforeSearch,productTile},
    }
</script>
<style lang="scss" scoped>
    @import '../styles/variables';

    .fade-enter-active, .fade-leave-active {
    transition: opacity .5s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
    }

    .noDataSearch
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 

4.768rem;
        margin-bottom: 

4.768rem;
        user-select: none;
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;

        &__head
        {
            font-weight: 500;
        }
    }

    .search
    {
        width: 100vw;
        height: auto;
        margin-top: 10.527em;



        &__content
        {
            display: flex;
            flex-direction: column;
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;

            &__results
            {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                margin-top: 1rem;
                margin-bottom: 1rem;

                &__text
                {
                    margin-top: 1rem;
                    user-select: none;
                    color: $gray;
                }

                &__row
                {
                    display: flex;
                    margin-top: 1.25rem;
                    margin-bottom: 1.25rem;
                    justify-content: space-evenly;
                    width: 100%;
                }

            }

            &__texts
            {
                width: 100%;
                margin-bottom: 1rem;
                h1 {
                    text-align: left;
                    user-select: none;
                    margin: 0;
                    padding: 0;
                    font-family: 'Poppins', sans-serif !important;
                    font-weight: 500;
                    font-size: 1.25rem;
                }
                h3
                {
                    text-align: left;
                    font-weight: 400;
                    padding: 0;
                    font-size: 1rem;
                    margin: 0;
                }
            }

            &__input
            {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-top: 1rem;
                margin-bottom: 1rem;
                box-sizing: border-box;

                &__getQuery
                {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    input
                    {
                        width: 35%;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        font-weight: 400;
                        text-indent: 1px;
                        text-overflow: '';
                        font-size: 1rem;
                        z-index: 2;
                        border: none;
                        background: none;
                        font-size: 1.25rem;
                        outline: none;
                        color: $gray;
                        transition: all 0.4s ease-in-out;
                        padding-bottom: 0.64rem;
                        padding-top: 0.64rem;
                        padding-left: 2.441rem;
                        padding-right: 2.441rem;
                        border-bottom: 1px solid #dddddd;
                        opacity: 0.7rem;
                        transition: all 0.4s ease-in;
                    }

                    input::placeholder
                    {
                        color: $gray;
                        opacity: 0.4;
                        font-size: 1rem;
                    }

                    input[type="text"]:focus
                    {
                        
                        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                        opacity: 1;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        padding-left: 3.052rem;
                        padding-right: 3.052rem;
                    }

                    button
                    {
                        margin-left: 1rem;
                        border: 2px solid $red;
                        color: $red;
                        font-size: 1rem;
                        background: none;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        padding-top: 0.64rem;
                        padding-bottom: 0.64rem;
                        border-radius: 5px;
                        transition: all 0.4s ease-in-out;
                    }

                    button:hover
                    {
                        cursor: pointer;
                        border: 2px solid $redHover;
                        color: $redHover;
                        border-radius: 10px;
                        transform: translateY(-1px);
                        box-shadow: 0 1px 3px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.24);
                    }



                    &__icon
                    {
                        position: relative;
                        left: 25px;
                        z-index: 1;
                        color: $black;
                    }

                }












 
            }
        }
    }




    @media (max-width: 1024px)
    {
        .search__content__results__row
        {
            flex-direction: column;
            width: 80%;
        }


    }
    @media (max-width: 769px) {
    .search{
        margin-top: 4.768em;
    }
}



</style>

<style lang="scss" scoped>
@import "../styles/variables";
.checkbox {
  width: 15px;
  height: 15px;
  position: relative;
  label {
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    // background: linear-gradient(top, #ffffff 0%, #eeeeee 40%, #ececec 100%);
    border-radius: 4px;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
    transition: all 0.2s ease-in-out;
    &:after {
      content: '';
      width: 7px;
      height: 4px;
      position: absolute;
      top: 3px;
      left: 3px;
      border: 2px solid $red;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
      transition: all 0.2s ease-in-out;
    }
    &:hover::after {
      opacity: 0.2;
    }
  }
  label:hover{
      background: #ffffff;
    // background: linear-gradient(top, #ffffff 0%, #e9e9e9 40%, #e0e0e0 100%);
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.8);
  }
  input[type=checkbox] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }    
}

.modal{
    width: 22.737rem;
    height: 100vh;
    // padding: 1.563rem;
    background: white;
    animation: fadeInRight 0.5s ease-in-out;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &__close{   
        z-index: 1;
        padding-top: 1.563rem;
        padding-left: 1.563rem;
        padding-right: 1.563rem;
        display: flex;
        height: 4.126rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid #ececec;
        span{
            font-size: 1rem;
            margin: 0;
            padding: 0;
            margin-right: auto;
            height: 1.563rem;
        }
        i{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        font-size: 1rem;
        width: 1rem;
        height: 1rem;
        color: black;
        transition: all 0.2s ease-in-out;
        }
        i::before{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1rem;
            height: 1rem;
        }
        i:hover{
            color: $red;
            cursor: pointer;
        }
    }
    &__loading
    {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 1.563rem;
        padding-right: 1.563rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow-y: auto !important;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        height: calc(100% - 4.126rem - 4.563rem);
    };

    &__filters{
        display: flex;
        flex-direction: column;
        height: calc(100% - 4.126rem - 4.563rem);
        padding-left: 1.563rem;
        padding-right: 1.563rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow-y: auto !important;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &__category{
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            &__name{
                display: flex;
                user-select: none;
                flex-direction: column;
                margin-bottom: 0.21rem;
                h4{
                    padding: 0;
                    margin: 0;
                    font-size: 0.8rem;
                    font-weight: 500;
                    line-height: 1.25rem;
                }
                span{
                    user-select: none;
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: #6e6e6e;
                    cursor: pointer;
                    margin: 0;
                    padding: 0;
                    line-height: 1rem;
                    transition: all 0.2s ease-in-out;
                }
                span:hover{
                    user-select: none;
                    color: $red;
                }
            }
                &__noData
                {
                    user-select: none;
                    font-size: 0.8rem;
                    opacity: 0.4
                }

                &__filter{
                display: flex;
                align-items: center;
                margin-top: 0.21rem;
                span{
                    user-select: none;
                    font-size: 0.8rem;
                    margin-left: 0.41rem;
                    padding-top: 1px;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;
                    span{
                        user-select: none;
                        font-size: 0.64rem;
                        margin: 0;
                        color: #6e6e6e;
                    }
                }
                span:hover{
                    user-select: none;
                    color: $red;
                    span{
                        color: $red;
                    }
                }
            }
            &__priceBox{
                display: flex;
                align-items: center;
                height: 1.953rem;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                margin-top: 0.41rem;
                &__price{
                    display: flex;
                    align-items: center;
                    padding-left: 0.41rem;
                    padding-right: 0.41rem;
                    // border: 1px solid #333;
                    border-radius: 5px;
                    height: 100%;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    background: #ffffff;
                    // background: linear-gradient(top, #ffffff 0%, #eeeeee 40%, #ececec 100%);
                    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
                    cursor: text;
                    transition: all 0.3s ease-in-out;
                    span{
                        font-size: 0.8rem;
                        font-weight: 400;
                    }
                    input{
                        width: 3.815rem;
                        background: none;
                        // height: calc(100%-2px);
                        height: 100%;
                        border: none !important;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        font-family: 'Poppins', sans-serif !important;
                    }
                    input:focus{
  outline-style: none;
  box-shadow: none;
  border-color: none;
  background-color: none;
  color: none;

                    }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                    -moz-appearance: textfield;
                    }
                
                }
                &__price:hover{
                    background: #ffffff;
                    // background: linear-gradient(top, #ffffff 0%, #e9e9e9 40%, #e0e0e0 100%);
                    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.8);
                }
                span{
                    margin-left: 0.41rem;
                    margin-right: 0.41rem;
                }
            }
            &__select{
                background: #ffffff;
                // background: linear-gradient(top, #ffffff 0%, #eeeeee 40%, #ececec 100%) !important;
                box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5) !important;
                border-radius: 5px;
                margin-top: 0.21rem;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                select{
                    padding: 0;
                    margin: 0;
                    height: 1.953rem;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    font-family: 'Poppins', sans-serif !important;
                    font-size: 0.8rem;
                    padding: 0.21rem;
                    border: none;
                    outline-style: none;
                    box-shadow: none;
                    border-color: none;
                    background-color: none;
                    background: #ffffff;
                    color: none;
                    color: black;
                    border-radius: 5px;
                }
            }
            &__select:hover{
                background: #ffffff;
                // background: linear-gradient(top, #ffffff 0%, #e9e9e9 40%, #e0e0e0 100%);
                box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.8);
            }
        }
        &__category:last-child{
            margin: 0;
        }
        
    }
    &__save{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 9999 !important;
        height: 4.563rem;
        width: 100%;
        background: pink;
        padding-top: 1rem;
        padding-left: 1.563rem;
        padding-right: 1.563rem;
        padding-bottom: 1rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-top: 1px solid #ececec;
        background: #f7f7f7;
        button{
            cursor: pointer;
            padding: 0.64rem;
            padding-left: 1rem;
            padding-right: 1rem;
            border: none;
            border-radius: 5px;
            font-size: 0.8rem;
            font-weight: 400;
            border: 1px solid #d1d1d1;
        }

        button:hover
        {
            opacity: 0.6;
        }
    }
}
.hide{
    display: none;
}

.saveButton{
    margin-left: 0.64rem;
}

.backdrop {
    z-index: 1001;
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@keyframes fadeInRight {
  from {
    opacity: 1;
    transform: translateX(650px);
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 500px) {
    
    .modal{
        width: 100vw;
        height: 100%;
        &__filters{
            &__category{
                &__name{
                    span{
                        margin-top: 0.21rem;
                        margin-bottom: 0.21rem;
                    }
                }
            }
        }
        }
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 5px solid $red; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
